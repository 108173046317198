import { graphql, useStaticQuery } from 'gatsby';
import * as transformers from '@content/transformers/MultiLanguagesPage';

export const multiLanguagesPageData = () => {
  const data = useStaticQuery(graphql`
    query multiLanguagesPageQuery {
      dGraphQl {
        nodeQuery(
          filter: {
            conditions: {
              field: "type",
              value: "multilanguages_page"
            }
          }
        ) {
          entities {
            ... on DrupalGraphQl_NodeMultilanguagesPage {
              entityLabel
              entityUrl {
                path
              }
              uuid
              fieldMetaTitle
              fieldMetatags {
                entity {
                  ... on DrupalGraphQl_ParagraphMetatags {
                    fieldDescription
                    fieldKeywords
                    entityBundle
                  }
                }
              }
              fieldBannerTop {
                entity {
                  ...DrupalGraphQlParagraphBannerPage
                }
              }
              fieldDescription {
                processed
              }
              fieldFlag {
                alt
                title
                url
              }
              fieldLinkFullList {
                title
                url {
                  path
                }
              }
              fieldLinkLanguages {
                title
                url {
                  path
                }
              }
            }
          }
        }
      }
    }
  `);

  return transformers.multiLanguagesPageTransform(data.dGraphQl);
}


