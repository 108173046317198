import React from 'react';

// === Components === //
import { Redirect } from '@reach/router';
import Page from '@components/layout/Page';
import Container from '@components/common/Container';

// === Content === //
import { multiLanguagesPageData } from '@graphql/MultiLanguagesPage';

// === Helpers === //
import * as utils from '@utils';

// === Styles === //
import styled from '@emotion/styled';
import { colors, dimensions, fluidRange } from '@styles';

// === Types === //
import { PageType, Variant } from '@content/types/layout';
import { IMultilanguagesPage } from '@content/types/languagesPage';
import ButtonLink from '@components/common/ButtonLink';

const Wrapper = styled.div`
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: ${dimensions.fontSize.regular}px;
    line-height: 20px;
    margin-bottom: 20px;
    color: ${colors.text.light};
  }

  h1,
  h2,
  h3,
  h4 {
    ${fluidRange('marginTop', '48px', '60px')}
    ${fluidRange('marginBottom', '20px', '30px')}
  }
`;

const Inner = styled.div`
  text-align: center;
`;

const Header = styled.h1`
  color: ${colors.white};
  width: 100%;
  margin: 0 0 20px !important;
  /* text-shadow: 0px 6px 15px rgba(0, 0, 0, 0.686); */

  span {
    display: block;
  }

  p {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
  }
`;

const Image = styled.img`
  max-width: 100%;
`;

const ButtonsWrapper = styled.div`
  margin: 40px 0 27px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 14px;
`;

const Description = styled.div`
  font-weight: 700;
  color: ${colors.white};
  /* text-shadow: 0px 6px 15px rgba(0, 0, 0, 0.686); */
  margin: 27px 0;

  ${fluidRange('fontSize', '14px', '24px')}
  ${fluidRange('lineHeight', '25px', '45px')}

  p {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
  }
`;

interface LanguagesPageProps {
  pageContext?: LanguagesPageContextData;
}

interface LanguagesPageContextData {
  slug: string;
  uuid: string;
  variant: Variant;
}

const MultiLanguagesPage = ({ pageContext }: LanguagesPageProps) => {
  const pageType: PageType = 'multiLanguages_page';
  const pages: IMultilanguagesPage[] = multiLanguagesPageData();
  const page: IMultilanguagesPage | undefined =
    pages.find((page) => page.uuid === pageContext?.uuid) || undefined;

  return page ? (
    <Page
      variant={pageContext?.variant || 'blue'}
      pageType={pageType}
      navUuid={page.uuid}
      bottomSpace={true}
      metaData={page.metaData}
      pageUrl={`/${pageContext?.slug || ''}`}
    >
      <Wrapper style={{ backgroundImage: `url(${page.heroBanner.image.src})` }}>
        <Container>
          <Inner>
            <Header>{utils.SafeHtml(page.heroBanner.text)}</Header>
            {page.flag && (
              <Image
                src={page.flag.src}
                alt={page.flag.alt}
                title={page.flag.title}
                height="55px"
                width="55px"
              />
            )}

            <ButtonsWrapper>
              {page.buttons.map((button, index) => (
                <ButtonLink link={button.url} variant={pageContext?.variant || 'blue'}>
                  {button.title}
                </ButtonLink>
              ))}
            </ButtonsWrapper>

            <Description>{utils.SafeHtml(page.description)}</Description>

            <div>
              <ButtonLink
                link={`${(pageContext?.variant || 'blue') === 'blue' ? '' : '/hcp'}${
                  page.goBack.url
                }`}
                variant={pageContext?.variant || 'blue'}
              >
                {page.goBack.title}
              </ButtonLink>
            </div>
          </Inner>
        </Container>
      </Wrapper>
    </Page>
  ) : (
    <Redirect to="/404" />
  );
};

export default MultiLanguagesPage;
